<template>
    <FormWrapper :is-loading="isLoading">
        <PageTitle title="Message Detail" slot="title" link="/helpContent/Message Detail" />
        <div v-html="messageSubject" id="heading"></div>
        <br />
        <br />
        <div v-html="messageEmailBody" id="email-body"></div>
        <br />
        <br />
        <FormRow>
            <div class="col-sm-6">
                <ButtonGroup>
                    <FormButton type="info" @click="()=>{$router.go(-1)}">Back</FormButton>
                </ButtonGroup>
            </div>
        </FormRow>
    </FormWrapper>
</template>

<script>
    import FormWrapper from 'Components/form/FormWrapper';
    import PageTitle from 'Components/layout/PageTitle';
    import loadingMixin from 'Mixins/loadingMixin';
    import {getMessageList} from '../api';
    export default {
        name: "Detail",
        mixins: [loadingMixin],
        components: {
            FormWrapper,
            PageTitle
        },
        data() {
            return {
                messageEmailBody: "",
                messageSubject: ""
            }
        },
        computed: {
          userData() {
              return this.$store.state.user.user
            }
        },
        watch: {
        },
        created() {
            this.getData()
        },
        methods: {
            getData() {
                setTimeout(() => {
                    this.showLoader();
                    const filters = {};
                    filters.messageId = this.$route.params.id?atob(this.$route.params.id):0
                    filters.viewType = this.$route.params.type?atob(this.$route.params.type):''
                    const data = {
                        filterjson: {
                            filter: [filters]
                        }
                    };
                    getMessageList(data)
                        .then(this.handleResponse)
                        .catch(this.handleError)
                },
                50
                )
            },
            handleResponse(response) {
                this.hideLoader();
                this.messageEmailBody = response.data[0].body_email;
                this.messageSubject = response.data[0].subject;
            },
        }
    }
</script>
<style lang="scss">
    #heading{
        background-color: #2196F3;
        height: 25px;
        font: white;
        color: White;
        padding: 4px;
        font-weight: bold;
        width: 100%;
        vertical-align: middle;
        border-radius: 5px;
    }

    #email-body{
        padding-left: 5%;
    }
</style>